"use client";

import type React from "react";
import { useState, useEffect } from "react";
import Image from "next/image";
import { FaTimes } from "react-icons/fa";
import Cookies from "js-cookie";

const InstallBanner: React.FC = () => {
	const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
	const [showBanner, setShowBanner] = useState(false);

	useEffect(() => {
		const handleBeforeInstallPrompt = (e: any) => {
			console.log("beforeinstallprompt event fired");
			e.preventDefault();
			setDeferredPrompt(e);
			if (!Cookies.get("installBannerDismissed")) {
				setShowBanner(true);
			}
		};

		window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

		// Fallback to show banner if event doesn't fire
		const timeoutId = setTimeout(() => {
			if (!Cookies.get("installBannerDismissed") && !deferredPrompt) {
				setShowBanner(true);
			}
		}, 2000);

		return () => {
			window.removeEventListener(
				"beforeinstallprompt",
				handleBeforeInstallPrompt,
			);
			clearTimeout(timeoutId);
		};
	}, [deferredPrompt]);

	const handleInstallClick = async () => {
		if (deferredPrompt) {
			deferredPrompt.prompt();
			const { outcome } = await deferredPrompt.userChoice;
			if (outcome === "accepted") {
				setShowBanner(false);
				Cookies.set("installBannerDismissed", "true", { expires: 30 }); // Cookie expires in 30 days
			}
			setDeferredPrompt(null);
		}
	};

	const handleCloseClick = () => {
		setShowBanner(false);
		Cookies.set("installBannerDismissed", "true", { expires: 30 }); // Cookie expires in 30 days
	};

	if (!showBanner) {
		return null;
	}

	return (
		<div className="fixed bottom-0 left-0 right-0 bg-white shadow-md p-4 flex items-center justify-between z-50">
			<div className="flex items-center">
				<Image
					src="/FireCompli-logo.png"
					alt="FireCompli Logo"
					width={40}
					height={40}
				/>
				<p className="ml-4 text-gray-700">
					Install FireCompli for a better experience.
				</p>
			</div>
			<div className="flex items-center">
				<button
					onClick={handleInstallClick}
					className="bg-blue-600 text-white px-4 py-2 rounded-md mr-4"
				>
					Install
				</button>
				<button onClick={handleCloseClick} className="text-gray-500">
					<FaTimes />
				</button>
			</div>
		</div>
	);
};

export default InstallBanner;
